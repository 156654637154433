body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-width: 350px;
}
.active {
  border: thin double rgb(114, 191, 244) !important;
}
.activeTop {
  border-top: thin double rgb(114, 191, 244) !important;
  border-left: thin double rgb(114, 191, 244) !important;
  border-right: thin double rgb(114, 191, 244) !important;
}
.activeMid {
  border-left: thin double rgb(114, 191, 244) !important;
  border-right: thin double rgb(114, 191, 244) !important;
}
.activeBottom {
  border-left: thin double rgb(114, 191, 244) !important;
  border-right: thin double rgb(114, 191, 244) !important;
  border-bottom: thin double rgb(114, 191, 244) !important;
}

.pagination .active {
  border: none !important;
}

.Toast {
  margin-top: 50px;
  opacity: 0.95;
}
.Toast > div {
  border-radius: 5px;
  text-align: center;
}
